.ConsoleBlock_wrapper {
	border: 1px solid #dfdfdf;
	font-size: .8rem;
	position: relative;
	margin: 16px 24px 16px
}

.ConsoleBlock_wrapper:after {
	content: "CONSOLE";
	position: absolute;
	top: -22px;
	right: 0;
	color: var(--gray-color);
	font-size: .75rem
}

.ConsoleBlock_wrapper .ConsoleBlock_inner {
	width: 100%;
	min-height: 60px;
	max-height: 120px
}

.ConsoleBlock_wrapper .ConsoleBlock_log,
.ConsoleBlock_wrapper .ConsoleBlock_warn,
.ConsoleBlock_wrapper .ConsoleBlock_error,
.ConsoleBlock_wrapper .ConsoleBlock_unknown {
	padding: 6px 4px;
	border-bottom: 1px solid #f0f0f0
}

.ConsoleBlock_wrapper .ConsoleBlock_log span,
.ConsoleBlock_wrapper .ConsoleBlock_warn span,
.ConsoleBlock_wrapper .ConsoleBlock_error span,
.ConsoleBlock_wrapper .ConsoleBlock_unknown span {
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word
}

.ConsoleBlock_wrapper .ConsoleBlock_unknown,
.ConsoleBlock_wrapper .ConsoleBlock_log {
	color: #c41a17
}

.ConsoleBlock_wrapper .ConsoleBlock_unknown::before,
.ConsoleBlock_wrapper .ConsoleBlock_log::before {
	margin-left: 11px
}

.ConsoleBlock_wrapper .ConsoleBlock_unknown::before,
.ConsoleBlock_wrapper .ConsoleBlock_unknown::after,
.ConsoleBlock_wrapper .ConsoleBlock_log::before,
.ConsoleBlock_wrapper .ConsoleBlock_log::after {
	content: '"';
	color: var(--text-color)
}

.ConsoleBlock_wrapper .ConsoleBlock_warn {
	background-color: #fffbe5
}

.ConsoleBlock_wrapper .ConsoleBlock_warn .ConsoleBlock_icon {
	color: #f4bd00;
	font-size: .675rem;
	margin-right: 4px
}

.ConsoleBlock_wrapper .ConsoleBlock_error {
	color: #ec3a41;
	background-color: #fff0f0
}

.ConsoleBlock_wrapper .ConsoleBlock_error .ConsoleBlock_icon {
	color: #ec3a41;
	font-size: .725rem;
	margin-right: 4px
}

.TextContent_wrapper {
	flex: 1 1;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative
}

.TextContent_wrapper .TextContent_minimizedBgr,
.TextContent_wrapper .TextContent_minimizedBar {
	opacity: 0;
	visibility: hidden;
	position: absolute
}

.TextContent_wrapper .TextContent_minimizedBgr {
	content: "";
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	cursor: e-resize;
	background-color: #33485f
}

.TextContent_wrapper .TextContent_minimizedBar {
	top: 50%;
	left: 50%;
	color: #aaaebc;
	white-space: nowrap;
	cursor: e-resize;
	z-index: 1;
	transform: translate(-50%, -50%) rotate(90deg)
}

.TextContent_wrapper.TextContent_minimized {
	overflow: hidden
}

.TextContent_wrapper.TextContent_minimized .TextContent_content {
	padding: 0
}

.TextContent_wrapper.TextContent_minimized .TextContent_minimizedBgr,
.TextContent_wrapper.TextContent_minimized .TextContent_minimizedBar {
	opacity: 1;
	visibility: visible;
	position: absolute;
	z-index: 6
}

.TextContent_pointInput {
	border: solid 1px #ccc;
	padding: 2px 4px;
	outline: none;
	width: 60px
}

.TextContent_pointInput:focus {
	border-color: var(--primary-color)
}

.TextContent_pointInput::-webkit-outer-spin-button,
.TextContent_pointInput::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0
}

.TextContent_pointInput[type=number] {
	-moz-appearance: textfield
}

.TextContent_infoBlock {
	padding: 8px 24px 16px;
	display: flex;
	flex-wrap: wrap;
	align-items: center
}

.TextContent_avatarBlock {
	display: flex;
	align-items: center
}

.TextContent_avatar {
	width: 24px;
	cursor: pointer;
	border-radius: 3px;
	margin-right: 8px;
	margin-top: 12px;
	border-radius: 50%
}

.TextContent_userName {
	font-size: .875rem;
	margin-right: 24px;
	color: var(--primary-color);
	margin-top: 12px;
	white-space: nowrap
}

.TextContent_userName:hover {
	cursor: pointer;
	text-decoration: underline
}

.TextContent_levelBox {
	font-size: .8rem;
	padding: 2px 8px 3px;
	border-radius: 10px;
	margin-right: 20px;
	margin-top: 12px;
	transition: all .2s linear
}

.TextContent_levelBox.TextContent_test,
.TextContent_levelBox.TextContent_quiz {
	background-color: var(--primary-color);
	color: var(--white-color)
}

.TextContent_levelBox.TextContent_editing {
	margin-right: 12px;
	cursor: pointer
}

.TextContent_levelBox.TextContent_easy {
	background-color: #7bc043;
	color: var(--white-color)
}

.TextContent_levelBox.TextContent_medium {
	background-color: #f1ad4e;
	color: var(--white-color)
}

.TextContent_levelBox.TextContent_hard {
	background-color: #ff3748;
	color: var(--white-color)
}

.TextContent_levelBox.TextContent_noSelect {
	color: var(--text-color);
	background-color: #fafafa
}

.TextContent_levelBox.TextContent_noSelect:hover {
	background-color: #ebebeb
}

.TextContent_pointBox {
	font-size: .875rem;
	display: flex;
	align-items: center;
	margin-top: 12px
}

.TextContent_pointBox span {
	white-space: nowrap
}

.TextContent_iconWrap {
	--height: 18px;
	width: var(--height);
	height: var(--height);
	line-height: var(--height);
	margin-right: 4px;
	text-align: center;
	border-radius: 50%;
	background-color: var(--primary-color)
}

.TextContent_icon {
	font-size: 10px;
	color: var(--white-color)
}

.TextContent_htmlBlock {
	background-color: transparent;
	width: 100%;
	outline: none;
	resize: none;
	min-height: 90px;
	border: 1px solid #ebebeb;
	padding: 8px 12px;
	font-size: .875rem;
	line-height: 1.5rem;
	font-weight: 400
}

.TextContent_bodyHTMLCode {
	margin-top: 16px !important;
	position: relative
}

.TextContent_bodyHTMLCode:after {
	content: "HTML BODY";
	position: absolute;
	top: -22px;
	right: 0;
	color: var(--gray-color);
	font-size: .75rem;
	font-family: "Open Sans", sans-serif
}

.TextContent_bodyHTMLPreview {
	border: 1px solid #dfdfdf;
	position: relative;
	padding: 0 16px;
	min-height: 40px;
	margin-top: 28px;
	background-color: var(--white-color);
	font-family: "Open Sans", sans-serif
}

.TextContent_bodyHTMLPreview:after {
	content: "PREVIEW";
	position: absolute;
	top: -22px;
	right: 0;
	color: var(--gray-color);
	font-size: .75rem
}

.TextContent_bodyHTMLPreview h1 {
	font-size: 1.275rem
}

.TextContent_bodyHTMLPreview h2 {
	font-size: 1.25rem
}

.TextContent_bodyHTMLPreview h3 {
	font-size: 1.125rem
}

.TextContent_bodyHTMLPreview h4 {
	font-size: 1rem
}

.TextContent_bodyHTMLPreview h5 {
	font-size: .875rem
}

.TextContent_myContent,
.TextContent_content {
	padding: 0 24px 16px;
	font-size: .875rem;
	line-height: 1.6
}

.TextContent_myContent>h3,
.TextContent_content>h3 {
	font-size: 1.125rem
}

.TextContent_content {
	flex: 1 1
}

.TextContent_content h1,
.TextContent_content h2,
.TextContent_content h3,
.TextContent_content h4,
.TextContent_content h5,
.TextContent_content h6 {
	margin-bottom: 0
}

.TextContent_content h1+p,
.TextContent_content h1+table,
.TextContent_content h1+div,
.TextContent_content h2+p,
.TextContent_content h2+table,
.TextContent_content h2+div,
.TextContent_content h3+p,
.TextContent_content h3+table,
.TextContent_content h3+div,
.TextContent_content h4+p,
.TextContent_content h4+table,
.TextContent_content h4+div,
.TextContent_content h5+p,
.TextContent_content h5+table,
.TextContent_content h5+div,
.TextContent_content h6+p,
.TextContent_content h6+table,
.TextContent_content h6+div {
	margin-top: 8px
}

.TextContent_content h1 {
	font-size: 1.275rem
}

.TextContent_content h2 {
	font-size: 1.25rem
}

.TextContent_content h3 {
	font-size: 1.125rem
}

.TextContent_content h4 {
	font-size: 1rem
}

.TextContent_content h5 {
	font-size: .875rem
}

.TextContent_content hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border: 0;
	border-top: 1px solid #ccc
}

.TextContent_content table {
	width: 100%;
	border-bottom: 1px solid #e6dfdd;
	border-right: 1px solid #e6dfdd;
	border-spacing: 0;
	border-collapse: separate
}

.TextContent_content table tr td,
.TextContent_content table tr th {
	text-align: left;
	font-size: .875rem;
	border-top: 1px solid #e6dfdd;
	border-left: 1px solid #e6dfdd;
	line-height: 1.8rem;
	padding: 0 4px 0 12px
}

.TextContent_content code:not([class^=language-]) {
	padding: 0px 4px 0px 4px;
	color: var(--text-color);
	background-color: #f7f7f7;
	border-radius: 4px
}

.TextContent_content blockquote {
	background-color: #f7f9fa;
	border-left: 5px solid var(--primary-color);
	margin-left: 0;
	margin-right: 0;
	padding: 2px 24px
}

.TextContent_footer {
	height: 38px
}

.TextContent_video>div {
	width: 100%
}

.TextContent_videoToggle {
	margin-left: 24px;
	display: inline-block;
	background-color: red;
	padding: 0 8px 3px;
	border-radius: 10px;
	opacity: .8
}

.TextContent_videoToggle.TextContent_shown {
	background-color: var(--gray-color)
}

.TextContent_videoToggle:hover {
	cursor: pointer;
	opacity: 1
}

.TextContent_videoToggle .TextContent_icon {
	font-size: .875rem
}

.TextContent_videoToggle span {
	font-size: .8rem;
	margin-left: 4px;
	color: #fff
}