.azure-mp-container {
    background: #000;
    width: 100%;
    height: 0;
    display: block;
    padding: 0;
    position: relative;
    overflow: hidden;
    position: relative;
  }
  
  .azure-mp-container > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }