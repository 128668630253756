body {
  font-family: Open Sans, sans-serif;
  background-color: white;
  color: var(--text-color);
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  // line-height: 1.15;
}

.w-10px {
  width: 10px !important;
}

.h-10px {
  height: 10px !important;
}

.w-20px {
  width: 20px !important;
}

.h-20px {
  height: 20px !important;
}

.w-30px {
  width: 30px !important;
}

.h-30px {
  height: 30px !important;
}

.w-40px {
  width: 40px !important;
}

.h-40px {
  height: 40px !important;
}

.w-50px {
  width: 50px !important;
}

.h-50px {
  height: 50px !important;
}

.w-50pc {
  width: 50% !important;
}

.h-50pc {
  height: 50% !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.bg-orange {
  background-color: #c75533;
}

.bg-black {
  background-color: black;
}


h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: normal;
}

.cursor-pointer {
  cursor: pointer;
}

.text-purple {
  color: #800080;
}

.text-gold {
  color: gold;
}

.text-silver {
  color: silver;
}

.text-bronze {
  color: #CD7F32;
}

.hover-pointer {
  cursor: pointer;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.bottom-0 {
  bottom: 0;
}

.text-orange {
  color: #c75533;
}

.bg-orange {
  background-color: #c75533;
}

.pointer-events-none {
  pointer-events: none;
}