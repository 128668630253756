// Here you can add other styles
.row {
  margin-right: 0px;
  margin-left: 0px;
}

.form-control {
  font-size: 1rem;
}

.ck-sticky-panel__content_sticky {
  margin-top: 120px !important;
}

.ck-widget.raw-html-embed .raw-html-embed__source {
  resize: vertical !important;
  min-height: 500px;
}

.react-bootstrap-table th .order > .dropdown > .caret {
  // margin: 10px 0 10px 5px;
  color: #cccccc;
}

.react-bootstrap-table th .order > .dropup > .caret {
  // margin: 10px 0;
  color: #cccccc;
}

table thead th .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: '';
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a {
  width: 100%;
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: #f90;
      color: #fff;
    }
  }
  &-disabled {
    background-color: #ddd;
    cursor: not-allowed;
    &:before {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
}

.filter-form .react-datetime-picker__wrapper {
  border: none;
}

.form-group .react-datetime-picker__wrapper {
  border: none;
}

.react-bootstrap-table-page-btns-ul {
  float: right;
}